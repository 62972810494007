<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="wrapper--static">
      <div class="container py-4">
        <div class="d-flex flex-wrap">
          <identity-form class="col-12 col-md-6 mb-2" ref="identityForm"></identity-form>
          <div class="col-12 col-md-6">
            <listing-preview class="mb-4" :listing="listing"></listing-preview>
            <loan-form class="mb-4" :listing="listing" ref="loanForm"></loan-form>
            <div class="btn btn-primary float-right" @click="validateSubmit">
              {{ $t('mortgage.enquireMortgage') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapState } from 'vuex';
const ListingPreview = () => import('@/components/mortgage/listing-preview');
const IdentityForm = () => import('@/components/mortgage/identity-form');
const LoanForm = () => import('@/components/mortgage/loan-form');

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ListingPreview,
    IdentityForm,
    LoanForm,
  },
  computed: {
    ...mapState({
      mobileWebView: state => state.global.mobileWebView,
      listing: state => state.v2.mortgage.listing,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('mortgage.enquireMortgage'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  data() {
    return {};
  },
  methods: {
    async loadFormData() {
      try {
        let result = await this.$store.dispatch(
          'v2/mortgage/getFormData',
          this.$route.query.listing_uuid,
        );
        if (!result.listing) {
          console.log('GET PROPERTY TYPES');
          await this.$store.dispatch('v2/masters/getPropertyTypes', {
            listingType: 'S',
          });
        } else {
          let ltv = await this.$store.dispatch('v2/mortgage/getLTV', result.listing);
          this.$store.commit('v2/mortgage/SET_LOAN_AMOUNT', result.listing.sell_price_raw * ltv);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    async submitMortgageRequest() {
      try {
        let result = await this.$store.dispatch('v2/mortgage/submitMortgageRequest');
        if (result.type === 'success')
          this.$swal({
            title: 'Success',
            text: this.mobileWebView
              ? this.$t('mortgage.modal.successMobile')
              : this.$t('mortgage.modal.success'),
            type: 'success',
            showConfirmButton: !this.mobileWebView,
            allowOutsideClick: false,
            // eslint-disable-next-line no-unused-vars
          }).then(async result => {
            if (!this.mobileWebView) {
              if (!this.listing) {
                await this.$router.push('/');
              } else {
                await this.$router.push(this.listing.web_url);
              }
            }
          });
        else {
          this.$swal('Error', this.$t('mortgage.modal.error'), 'error');
        }
      } catch (e) {
        this.$swal('Error', this.$t('mortgage.modal.error'), 'error');
      }
    },
    async validateSubmit() {
      let validationArray = [];
      validationArray.push(this.$refs.identityForm.validate());
      validationArray.push(this.$refs.loanForm.validate());
      let valid = await Promise.all(validationArray).then(function(results) {
        return (
          results.filter(function(result) {
            return !result;
          }).length === 0
        );
      });
      if (valid) {
        await this.submitMortgageRequest();
      } else {
        console.log('MORTGAGE FORM IS INVALID!');
      }
    },
  },
  created() {
    this.loadFormData();
  },
};
</script>
